let origin = "https://future.zhuneng.cn";
//课程列表
const courseListUrl = `/gateway/hc-edu/miniapi/cloudschool/courseList`;
//章节列表
const chapterListUrl = `/gateway/hc-edu/miniapi/cloudschool/chapterList`;
//课程详情
const courseDetailUrl = `/gateway/hc-edu/miniapi/cloudschool/courseInfo`;
//我的课程列表
const myCourseUrl = `/gateway/hc-edu/miniapi/cloudschool/myCourseList`;
//课程播放进度更新
const updateCourseUrl = `/gateway/hc-edu/miniapi/cloudschool/updateCoursePlaySchedule`;

export {
  courseListUrl,
  courseDetailUrl,
  myCourseUrl,
  updateCourseUrl,
  chapterListUrl,
};
